<template>
  <div class="menu" :class="localDisplayLang ? 'rtl-menu' : ''" id="menu">
    <div v-for="(item, index) in menuItems" :key="index" class="menu-box">
      <div
        class="menu-content"
        v-if="subscriberid ? (checkForBoldMenu(item) === true) && (item.isMenuEnabled)
        : item.isMenuEnabled && !item.isRequiredLogin"
        @click="menuSelect(item)"
      >
        <div class="menu-content-item">
          <img :src="item.icon" alt="test" />
          <p>{{ $t(`${item.title.default.toUpperCase()}`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import moment from "moment";

export default {
  props: {
    menuItems: {
      type: Array
    }
  },
  data() {
    return {
      localDisplayLang: null
    };
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "getRtl"])
  },
  created() {
    if (localStorage.getItem("setDisplayLanguageCode")) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    }
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  methods: {
    //checking if the age is matching to show bold category.
    checkForBoldMenu(item) {
      if (item.id === "BOLD") {
        if (localStorage.getItem("subscriberDetails")) {
          let details = JSON.parse(localStorage.getItem("subscriberDetails"));

          if (!details.data.dob) return false;

          let isKids = this.isKidsProfile(details.data.dob);

          if (isKids) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },

    //Checking if it is kids mode or not.
    isKidsProfile(dob) {
      let d = new Date();
      let currentYear = d.getFullYear();
      let userYear = moment(dob)
        .format("YYYY-MM-DD")
        .split("-")[0];
      if (currentYear - userYear >= 0) {
        if (currentYear - userYear < 14) {
          return true;
        } else {
          return false;
        }
      }
    },

    //selecting menu category and routing to that component.
    menuSelect(menuDetail) {
      if (menuDetail.id === "HOME") {
        this.$router.push({ name: "Home", params: { menuDetail } });
      } else if (menuDetail.id === "BOLD") {
        this.$router.push({ name: "Bold", params: { menuDetail } });
      } else if (menuDetail.id === "MOVIES") {
        this.$router.push({ name: "Movie", params: { menuDetail } });
      } else if (menuDetail.id === "TVSHOW") {
        this.$router.push({ name: "Tvshows", params: { menuDetail } });
      } else if (menuDetail.id === "MUSIC") {
        this.$router.push({ name: "Music", params: { menuDetail } });
      } else if (menuDetail.id === "LIVETV") {
        this.$router.push({ name: "Live", params: { menuDetail } });
      } else if (menuDetail.id === "BUNDLE") {
        this.$router.push({ name: "Bundles", params: { menuDetail } });
      } else if (menuDetail.id === "WATCHLIST") {
        this.$router.push({ name: "Watchlist", params: { menuDetail } });
      } else if (menuDetail.id === "PLAN") {
        let payload = { listType: "onlySubscribe" };
        eventBus.$emit("subscribePopup", payload);
      }
      //to close the menu template.
      this.$emit("closeMenu");
    }
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./Menu.scss"
</style>
